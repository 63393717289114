import React from 'react';
import axios from 'axios';

import LoginForm from './LoginForm'
import FlashMessage from './FlashMessage'

import logoText from '../logo_text.svg'

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.state = {
      formData: {
        email: '',
        password: ''
      },
      errorMessage: false
    }
  }
  handleFormSubmit(event) {
    event.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/auth/login`;
    const data = this.state.formData;
    axios.post(url, data)
      .then(res => {
        this.props.onLogin(res.data);
        this.clearForm()
      })
      .catch(err => {
        this.setState({errorMessage: true})
        this.clearForm()
      })
  }
  handleFormChange(event){
    const obj = this.state.formData;
    obj[event.target.name] = event.target.value;
    this.setState({formData: obj});
  }
  clearForm() {
    this.setState({
      formData: {
        email: '',
        password: ''
      }
    })
  }
    render() {
        return (
        <section className="hero">
            <div className="hero-body">
              <div className="container">
                <div className="columns">
                  <div className="column is-3"></div>
                  <div className="column is-6 ">  
                    <figure className="image is-3by1">
                      <img src={logoText}
                        alt="Solarly Logo"/>
                    </figure>
                    { this.state.errorMessage &&
                      <FlashMessage 
                        onClick={() => this.setState({errorMessage: false})}
                        message={"Sorry, wrong email/password. Please try again."} />
                    }
                    <LoginForm 
                      onSubmit={this.handleFormSubmit}
                      onChange={this.handleFormChange}
                      formData={this.state.formData}/>
                  </div>
                  <div className="column is-3"></div>
                </div>
              </div>
            </div>
         </section>  )
    }
}

export default LoginPage;