import React, {useEffect, useState} from 'react';
import ActionButton from "./ActionButton";
import axios from "axios";
import {toast} from "react-toastify";

function formatKey(key) {
    let parts = key.split('_');
    return parts
        .map(el => el[0].toUpperCase() + el.slice(1))
        .join(' ')
}

function CheckTitle(props) {
  if (!props.checkStatus) {
    return null;
  }

  return (
    <th><input type="checkbox" onChange={props.onChange} id={props.id} checked={props.checked}/> </th>
  );
}

function CheckRow(props) {
  if (!props.checkStatus) {
    return null;
  }

  return (
    <td><input type="checkbox" onChange={props.onChange} id={props.id} checked={props.checked}/></td>
  );
}

  function CheckOptions(props) {
  if (!props.checkStatus) {
    return null;
  }

  return (
      <>
          <nav className="navbar is-transparent">
              <div className="navbar-menu">
                  <div className="navbar-item">
                      <ActionButton text={"Activate SIM"} style={"is-success "} onClick={props.clickcAtionAc}/>
                  </div>
                  <div className="navbar-item">
                      <ActionButton text={"Suspend SIM"} style={"is-danger "} onClick={props.clickcAtionSu}/>
                  </div>

              </div>
          </nav>
    </>
  );
}


/**
 * tableData contains the data to be presented on the table
 * in case an icon has to be used in the place of text, 
 * add a xyz_icon attribute where xyz is the main attribute.
 * The ListTable presents xyz as an icon and places the text as a title attribute 
 */
const ListTable = function ({check, onChange, tableData,indexOfFirstRow=0, indexOfLastRow=0,paginationSelect=false, columnSelect= {},ColumnSelectDescription={},onClickRow, selectedColumn=false, headerCol = true, selectableStyle = '', ...rest}) {
    const [isChecked, setIsChecked] = useState(false)
    const [checkedData, setCheckedData] = useState([])
    const [rowChecked, setRowChecked] = useState({})
    let tableDataDisplay = tableData; // all the element of the selected page
    if (paginationSelect) {
        tableDataDisplay = tableData.slice(indexOfFirstRow, indexOfLastRow); // take only the row that need to be display
    }
    console.log(tableData)
    useEffect(() => {
        checkedData.length >0 ? setIsChecked(true) : setIsChecked(false);
    });

    function handleCheckChangeUnique(simId){
        let selection = [...checkedData];
        const keyIndex = checkedData.indexOf(simId);
        let rows = {...rowChecked}
        rows.title = false;
        rows[simId] = !rowChecked[simId]
        setRowChecked(rows);
        if(keyIndex>=0){
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            setCheckedData(selection);
        }else{
            setCheckedData([...checkedData,simId]);
        }
        console.log(rowChecked)
    }
    function handleCheckChangeAll(){
        console.log(rowChecked);
       let selection = [];
       let rows = {};
       tableData.map((el) => {
           if(rowChecked["title"]===false || checkedData.length===0) {
               selection.push(el.id)
               rows[el.id] = true;
               rows["title"] = true;
           }else if (rowChecked["title"]){
               rows[el.id] = false;
               rows["title"] = false;
           }
       })

       setCheckedData(selection);
       setRowChecked(rows);
       console.log(rowChecked);
    }



    function simAction(action){
        if(action === 1){console.log("Activate :",checkedData)}
        else if(action === 2){console.log("Suspend :",checkedData)}
        else if(action === 4){console.log("Factory Test :",checkedData)}
    }


    function simStatus(type){
        const baseUrl = process.env.REACT_APP_BACKEND_SERVICE_URL
        const status = {"status": type};
        const msgType = status["status"] === 1 ? "Command Activate" : "Command Suspend";
        const msg = msgType+" sim sended for solarlyboxe ";

        console.log("Command sended " )
        checkedData.map((el) => {
            toast.info(msg+el,{toastId: el});
            axios.patch(`${baseUrl}/sims/${el}`, { status})
                .then(res => {
                    console.log("Command passed successfully ");
                    setTimeout(()=>
                     toast.update(el, {
                        render: "Command passed successfully for solarlyboxe "+el,
                         type: toast.TYPE.SUCCESS,autoClose: 7000,}),3000);
                })
                .catch(err => {
                    console.log("Command did'nt passed successfully ");
                     setTimeout(()=>
                     toast.update(el, {
                        render: "Command did'nt passed successfully for solarlyboxe "+el,
                         type: toast.TYPE.ERROR,
                     autoClose: 7000,}),3000);
                })
        })
        setTimeout(() =>{window.location.reload(false);},10000)
    }

    return (
        <>
            {<CheckOptions checkStatus={isChecked} clickcAtionAc={()=>(simStatus(1))} clickcAtionSu={()=>(simStatus(2))}/>}
        {tableData.length !== 0 &&

        <table
            data-testid={rest['data-testid']}
            className={"table is-hoverable " + selectableStyle}>
            <thead>
            <tr>
                <CheckTitle checkStatus={check} onChange={() => handleCheckChangeAll()}  checked={rowChecked["title"]} id={"check-all-up"}/>
                {

                    Object.keys(tableData[0]).map((key, i) => {
                        // do not present any column with _icon suffix
                        if(!key.endsWith('_icon')){
                            if (selectedColumn) {
                                if (columnSelect[key]) {

                                    return (<th key={i}>{formatKey(ColumnSelectDescription[key])}</th>)
                                }
                            }
                            else {
                                return (<th key={i}>{formatKey(key)}</th>)
                            }
                        }
                    })
                }
            </tr>
            </thead>
            <tfoot>
            <tr>
                <CheckTitle checkStatus={check} onChange={() => handleCheckChangeAll()}  checked={rowChecked["title"]} id={"check-all-down"}/>
                {
                    Object.keys(tableData[0]).map((key, i) => {
                        // do not present any column with _icon suffix
                        if(!key.endsWith('_icon')){
                            if (selectedColumn) {
                                if (columnSelect[key]) {
                                    return (<th key={i}>{formatKey(ColumnSelectDescription[key])}</th>)
                                }
                            }
                            else {
                                return (<th key={i}>{formatKey(key)}</th>)
                            }
                        }
                    })
                }
            </tr>
            </tfoot>
            <tbody>
            {
                tableDataDisplay.map((el, i) => {
                    return (
                        <tr key={el.id} id={el.id} onDoubleClick={onClickRow} data-cy="list-table">
                            <CheckRow checkStatus={check} onChange={() => handleCheckChangeUnique(el.id)}  checked={rowChecked ? rowChecked[el.id] :false} id={"check-"&el.sim_emnify_id} />
                            {
                                /**
                                 * For every iteration, for each column name {column_name},
                                 * if there exist an attribute column_name_icon, use it instead
                                 * and place the {column_name} value as title attribute for better guiding the user
                                 */
                                Object.keys(el).map((key, j) => {
                                     if (!key.endsWith('_icon') && j === 0 && headerCol) {
                                         if (selectedColumn) {
                                             if (columnSelect[key]) {
                                                 return (<th key={j}>{el[key]}</th>)
                                             }

                                         }
                                         else {
                                           return (<th key={j}>{el[key]}</th>)
                                         }
                                       } else if(!key.endsWith('_icon')){

                                           if(el[key+"_icon"]){
                                               if (selectedColumn) {
                                                   if (columnSelect[key]) {
                                                       return (<td key={j}><img className="indicators" src={`${el[key + '_icon']}`} title={el[key]} data-testid={`${key}-${j}`}/></td>)
                                                   }
                                               }
                                               else {
                                                   return( <td key={j}><img className="indicators" src={`${el[key+'_icon']}`} title={el[key]} data-testid={`${key}-${j}`} /></td>)
                                               }
                                           }else{
                                               if (selectedColumn) {
                                                   if (columnSelect[key]) {
                                                       return (<td key={j}>{el[key]}</td>)
                                                   }
                                               }
                                               else {
                                                   return (<td key={j}>{el[key]}</td>)
                                               }
                                           }
                                       }

                                })

                            }
                        </tr>)
                })
            }
            </tbody>
        </table>
        }
        {(tableData.length === 0) &&
        <p className="subtitle is-4">
            There is currently no data.
        </p>
        }
        </>
    )
}

export default ListTable;