

export const configFields = {
	"Description": {
		"box_id": "Box ID",
		"batch_id": "Batch id",
		"bat_cap": "Battery capacity",
		"max_dod": "Maximum DoD",
		"soc_thr": "SOC recovery threshold",
		"conn_en": "Connected mode enable",
		"charge_en": "Charge enable",
		"output_en": "Output enable",
		"sms_en": "Remote control (SMS)",
		"gprs_en": "Remote control (GPRS)",
		"payg": "PayG",
		"payg_dl": "PayG deadline",
		"mqtt_user": "User id (MQTT)",
		"mqtt_addr": "Server IP (MQTT)",
		"mqtt_port": "Server port (MQTT)",
		"central_nbr": "Central phone number",
		"meas_cycle": "Measure cycle",
		"log_cycle": "Log cycle",
		"log_en": "Log enable",
		"token_count": "Count"

	},
	"Flash Name": {
		"box_id": "boxId",
		"batch_id": "",
		"bat_cap": "batcap",
		"max_dod": "maxDod",
		"soc_thr": "socRecoThr",
		"meas_cycle": "measureCycle",
		"conn_en": "connectedModeEnable",
		"charge_en": "chargeEnable",
		"output_en": "outputEnable",
		"sms_en": "remoteControlSms",
		"gprs_en": "remoteControlGprs",
		"payg": "payg",
		"payg_dl": "paygDeadline",
    	"mqtt_user": "userId",
		"mqtt_addr": "serverIP",
		"mqtt_port": "serverPort",
		"central_nbr": "centralPhoneNbr",
		"log_cycle": "logCycle",
		"log_en": "logEnable",
		"token_count": ""
	}
};

    