import React from 'react';
import { configFields } from './lookups/configFields'

const ConfigTable = function({config}) {

  return (
  <table className="table">
    <thead>
      <tr>
        <th>Description</th>
        <th>Flash Name</th>
        <th>Value</th>
      </tr>
    </thead>                          
    <tbody>
    {
      Object.keys(config).map( (key,i) => {
          if (Object.keys(configFields['Description']).includes(key)) {
          return (
            <tr key={i}>
              <th>{configFields['Description'][key]}</th>
              <td>{configFields['Flash Name'][key]}</td>
              <td>{config[key]}</td>
            </tr>
          )
        }
      })
    }
    </tbody>
  </table>
    )
}

export default ConfigTable;
