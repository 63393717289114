/*
    allow the selection of the column for the component listTable
    if new value => add <value : true or false>
    true = display
    false = not display
 */

export const ColumnSelectBox = {
    "box_name": true,
    "capacity": true,
    "connectivity_status": true,
    "firmware_version": false,
    "id": false,
    "location": true,
    "manufacturer_pcb_id": false,
    "payg": true,
    "pcb_id": false,
    "remark": true,
    "sim800_revision": false,
    "sim_emnify_id": false,
    "sim_iccid": false,
    "sim_status": true,
    "status": true,
    "contract_name":true,
    "end_user_name":true
};

export const ColumnSelectBoxDescription = {
    "box_name": "box_name",
    "capacity": "capacity",
    "connectivity_status": "connectivity_status",
    "firmware_version": "firmware_version",
    "id": "id",
    "location": "location",
    "manufacturer_pcb_id": "manufacturer_pcb_id",
    "payg": "payg",
    "pcb_id": "pcb_id",
    "remark": "remark",
    "sim800_revision": "sim800_revision",
    "sim_emnify_id": "sim_emnify_id",
    "sim_iccid": "sim_iccid",
    "sim_status": "sim_status",
    "status": "status",
    "contract_name":"contract",
    "end_user_name":"End-User"
};

export const ColumnSelectClient = {
    "status": true,
    "company_country": true,
    "company_name": true,
    "company_slug": true,
    "id": true,
    "solarly_count": true,
};

export const ColumnSelectClientDescription = {
    "status": "status",
    "company_country": "company_country",
    "company_name": "company_name",
    "company_slug": "company_slug",
    "id": "id",
    "solarly_count": "solarly_count",
};