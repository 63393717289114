import React from 'react';
import axios from 'axios';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import moment from 'moment';
import {
    paygStatusLookup,
    paygResultLookup,
    paygResultIconLookup,
    paygStatusIconLookup,
    paygChangeTypeLookup
} from './lookups/paygLookup';
import {ColumnSelectBox, ColumnSelectBoxDescription} from './lookups/ColumnSelect';

import Breadcrumbs from './Breadcrumbs'
import ListTable from './ListTable'
import SlyTabs from './SlyTabs'
import OneColTable from './OneColTable'
import ConfigTable from './ConfigTable'
import ActionButton from './ActionButton'
import PaygModal from './PaygModal'
import AnalysisPage from './AnalysisPage';
import LoadingView from './LoadingView';
import './PayGIcons.scss';
import './MySolarlyboxesPage.scss';
import Pagination from "./Pagination";
import LoadingSpinner from "./LoadingSpinner";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// TODO: Extract all logic and JSX related to the Solarlybox details into a new component
class MySolarlyboxesPage extends React.Component {
    constructor(props) {
        super(props);
        this.getSolarlyboxes = this.getSolarlyboxes.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
        this.setBreadcrumbProps = this.setBreadcrumbProps.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.getPaygHistory = this.getPaygHistory.bind(this);
        this.applyPaygChange = this.applyPaygChange.bind(this);
        this.processPaygChangeData = this.processPaygChangeData.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.loadData = this.loadData.bind(this);
        this.pollPayGState = this.pollPayGState.bind(this);
        this.hasPendingPayGOperation = this.hasPendingPayGOperation.bind(this);
        //bind solarly box get information for gauge and graphs
        this.getSolarlyBoxInformation = this.getSolarlyBoxInformation.bind(this);
        this.verifyPayGChangeable = this.verifyPayGChangeable.bind(this);
        this.search = this.search.bind(this);
        this.paginate = this.paginate.bind(this);
        this.onChangeOption = this.onChangeOption.bind(this);
        this.onFilterAction = this.onFilterAction.bind(this);
        // todo : paygConditions only for dev
        /*this.paygConditions2 = [{
		text:"Connection to SolarlyBox",
		status:true
	},{
		text:"No pending payg change",
		status:true
	}];*/
        this.state = {
            loadingPage: true, // false = load
            mySolarlyboxes: [], // data for the table mysolarlybox
            solarlyboxOverviewDataTable: [],
            solarlybox: null, // index of the solarlybox selected
            config: {}, // data for the configuration page of all the box display
            paygHistory: [],
            showPaygModal: false,
            //  added to contain the solarlybox_data
            selectedSolarlyboxData: {},
            loadingSolarlyBoxData: true,
            errorLoadingSolarlyBoxData: false,
            canUpdatePayG: false,
            PayGConditions: [],
            verifyingPayGChangeable: true,
            paygDl: null,
            query: "", // value of the button filter
            querys: "", // each new letter for query
            currentPage: 1, // current page display
            defaultValueOption: 5, // default value for number of row per page
            rowsPerPage: 5, // number of element per page
        }
    }

    componentDidMount() {
        this.getSolarlyboxes(solarlyboxOverviewData => {
            if (this.props.location.pathname.split("/").length > 2) {
                for (let counter = 0; counter < solarlyboxOverviewData.length; counter++) {
                    if (solarlyboxOverviewData[counter].id === parseInt(this.props.location.pathname.split("/")[2])) {
                        this.setState({loadingPage: false});
                        this.setState({solarlybox: counter});

                        this.loadData();

                        // in case of /mysolarlyboxes/1/{tab}
                        if (this.props.location.pathname.split("/").length > 3) {
                            switch (this.props.location.pathname.split("/")[3]) {
                                case 'payg':
                                    this.getPaygHistory();
                                    break;
                                case 'config':
                                    this.getConfig();
                                    break;
                            }
                        }
                        break;
                    }

                }
            } else {
                this.setState({loadingPage: false});
            }
        });

        //  if we are not on /mysolarlyboxes

    }

    componentDidUpdate() {
        if (this.props.location.pathname === "/mysolarlyboxes" && this.state.solarlybox !== null) {
            this.setState({solarlybox: null});
        }
    }

    componentWillUnmount() {
        // see https://stackoverflow.com/questions/51246815/cant-call-setstate-or-forceupdate-on-an-unmounted-component-this-is-a-no-op
        this.isUnmounted = true;
    }

    getSolarlyboxes(callback) {
        // Define API endpoints
        const baseUrl = process.env.REACT_APP_BACKEND_SERVICE_URL
        const url = `${baseUrl}/solarlyboxes`;
        axios.get(url)
            .then(res => {
                let solarlyboxOverviewData = res.data.solarlyboxes;
                // put the component LoadingSpinner for 3 value [sim_status, connectivity_status, paygs]
                solarlyboxOverviewData = solarlyboxOverviewData.map((slybox, index) => {
                    if (slybox !== this.state.mySolarlyboxes[index]) {
                        console.log('ok')
                    }
                    slybox['sim_status'] =
                        <LoadingSpinner maxSize="30px" id={slybox.id} solarlyboxOverviewData={this.state.mySolarlyboxes}
                                    baseUrl={baseUrl}/>;
                    slybox['connectivity_status'] =
                        <LoadingSpinner maxSize="30px" id={slybox.id} solarlyboxOverviewData={this.state.mySolarlyboxes}
                                        baseUrl={baseUrl}/>;
                    slybox['payg'] =
                        <LoadingSpinner maxSize="30px" id={slybox.id} solarlyboxOverviewData={this.state.mySolarlyboxes}
                                        baseUrl={baseUrl}/>;
                    return slybox
                });
                solarlyboxOverviewData.sort((a, b) => (a.box_name > b.box_name) ? 1 : -1) // sort the box
                this.setState({mySolarlyboxes:  this.search(solarlyboxOverviewData)}); // save value mySolarlyboxes

                if (callback) {
                    callback(solarlyboxOverviewData);
                }



                const indexOfLastRow = this.state.currentPage * this.state.rowsPerPage;
                const indexOfFirstRow = indexOfLastRow - this.state.rowsPerPage;

                // we created an array of Promises of fetching the data for each solarlybox
                this.search(this.state.mySolarlyboxes).map((slybox, index) => {
                    if (index >= indexOfFirstRow && index <= indexOfLastRow) {
                        const sim_url = `${baseUrl}/sims/${slybox.id}`;
                        const config_url = `${baseUrl}/solarlyboxes/${slybox.id}/config`;
                        const getSim = Promise.resolve(axios.get(sim_url)).catch(() => 'ERR')
                        const getConf = Promise.resolve(axios.get(config_url)).catch(() => 'ERR')
                        // 2 request (getSim, getConf), their result will replace the <LoadingSpinner> component in the board
                        Promise.all([getSim, getConf]).then(response => {
                            if (this.isUnmounted) {
                                return;
                            }
                            const {
                                status,
                                connectivity_status,
                                ...restSim
                            } = response[0] === 'ERR' ? {
                                status: 'ERR',
                                connectivity_status: 'ERR'
                            } : response[0].data.sim
                            const {
                                payg,
                                ...restConfig
                            } = response[1] === 'ERR' ? {payg: 'ERR'} : response[1].data.config
                            slybox['sim_status'] = status
                            slybox['connectivity_status'] = connectivity_status
                            slybox['payg'] = paygStatusLookup[payg]
                            console.log(response)
                            this.setState({mySolarlyboxes: solarlyboxOverviewData});


                        }).catch(err => {
                            console.log(err)
                            slybox['sim_status'] = 'ERR'
                            slybox['connectivity_status'] = 'ERR'
                            slybox['payg'] = 'ERR'

                            this.setState({mySolarlyboxes: solarlyboxOverviewData});
                            if (callback) {
                                callback(solarlyboxOverviewData);
                            }
                            toast.error("SolarlyBoxes didn't loaded successfully!");
                        })
                    }
                })

            })
    }
    // get the value of all the box for the configuration part and set in the config variable
    getConfig() {
        const solarlyboxId = this.state.mySolarlyboxes[this.state.solarlybox].id

        const url = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/solarlyboxes/${solarlyboxId}/config`;
        axios.get(url)
            .then(res => {
                console.log(res.data.config)
                this.setState({config: res.data.config});

            })
            .catch(err => {
                console.log(err);
            })
    }

    getPaygDl() {
        console.log(this.state.solarlybox)
        const solarlyboxId = this.state.mySolarlyboxes[this.state.solarlybox].id

        const url = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/solarlyboxes/${solarlyboxId}/config`;
        axios.get(url)
            .then(res => {
                this.setState({paygDl: res.data.config.payg_dl});
            })
            .catch(err => {
                console.log(err);
            })
    }

    formatDate(serverDate) {
        const date = new Date(serverDate)
        const dateString = date.toLocaleString('fr-BE', {
            'year': 'numeric',
            'month': '2-digit',
            'day': '2-digit',
            'hour': '2-digit',
            'minute': '2-digit'
        })
        return dateString
    }

    fomatDateUnix(dateUnix) {
        let date = moment.unix(dateUnix)
        date = date.format('DD[/]MM[/]YYYY')
        return date
    }

    processPaygChangeData(paygChange) {
        delete paygChange.id
        delete paygChange.solarlybox_id

        // add the icon parameters (_icon) such that the ListTable renders it instead of the status/result text
        if (paygChange['change_type'] === 0) {
            if (!paygChange['old_status_icon']) {
                paygChange['old_status_icon'] = paygStatusIconLookup[paygChange['old_status']];
            }
            if (!paygChange['new_status_icon']) {
                paygChange['new_status_icon'] = paygStatusIconLookup[paygChange['new_status']];
            }
            paygChange['old_status'] = paygStatusLookup[paygChange['old_status']];
            paygChange['new_status'] = paygStatusLookup[paygChange['new_status']];
            paygChange['old_payg_dl'] = "";
            paygChange['new_payg_dl'] = "";
        } else if (paygChange['change_type'] === 1) {
            paygChange['old_status'] = "";
            paygChange['new_status'] = "";
            paygChange['old_payg_dl'] = paygChange['old_payg_dl'] ? this.fomatDateUnix(paygChange['old_payg_dl']) : "";
            paygChange['new_payg_dl'] = this.fomatDateUnix(paygChange['new_payg_dl']);
        } else {
            paygChange['old_status'] = "";
            paygChange['new_status'] = "";
            paygChange['old_payg_dl'] = "";
            paygChange['new_payg_dl'] = "";
        }
        if (!paygChange['result_icon']) {
            paygChange['result_icon'] = paygResultIconLookup[paygChange['result']];
        }
        paygChange['result'] = paygResultLookup[paygChange['result']];
        paygChange['change_type'] = paygChangeTypeLookup[paygChange['change_type']];

        //calculate the duration.
        let duration = moment(paygChange['updated_at']).diff(moment(paygChange['applied_at']), 'seconds');
        //if less than a minute, all Good
        if (duration < 60) {
            duration += duration == 1 ? " second" : " seconds";

        }
        //if >=60seconds, convert to minute in 2 decimal places
        else {
            duration /= 60;
            duration = Math.round(duration * 100) / 100;
            duration += duration == 1 ? " minute" : " minutes";
        }

        // format UTC string
        paygChange['applied_at'] = this.formatDate(paygChange['applied_at']);

        if (paygChange['result'] != 'PENDING') {
            paygChange['duration'] = <div data-tooltip={this.formatDate(paygChange['updated_at'])}><i
                className="fas fa-stopwatch"/> &nbsp;{duration}</div>;//this.formatDate(paygChange['updated_at'])
        } else {
            paygChange['duration'] = '';
        }
        delete paygChange['updated_at'];
        return paygChange
    }

    /* when the payGHistory request fails eg due to network error
       increment the failure factor such that the request is tried again after a greater period of time
       in the case where the requests start going through, the failure factor is returned to 1;
    */
    failure_factor = 1;
    /*
       To avoid problems of unecessary extra computation on the client,keep the previous PayG state and compare before updating state
    */
    payg_status = '';

    getPaygHistory() {
        this.getPaygDl()
        // when you switch from one box to the other, this.state.mySolarlyboxes[this.state.solarlybox] seems to be undefined for a while
        if (this.state.mySolarlyboxes[this.state.solarlybox]) {
            const solarlyboxId = this.state.mySolarlyboxes[this.state.solarlybox].id
            const url = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/solarlyboxes/${solarlyboxId}/payg_history`;
            axios.get(url)
                .then(res => {
                    let paygHistory = res.data.payg_history
                    paygHistory.map(paygChange => {
                        this.processPaygChangeData(paygChange)
                    })
                    this.setState({paygHistory: paygHistory}, () => {
                        this.failure_factor = 1;
                        //read the array backwards
                        for (let cter = 0; cter < paygHistory.length; cter++) {
                            let historyItem = paygHistory[cter];
                            if (historyItem.result === "SUCCESS") {
                                //if the new status is different from that stored
                                if (this.payg_status !== historyItem.new_status) {
                                    //hold the solarlyboxes in a temporal variable
                                    let temporal_mysolarlyboxes = this.state.mySolarlyboxes;
                                    temporal_mysolarlyboxes[this.state.solarlybox].payg = historyItem.new_status;
                                    console.log("updating payg at" + historyItem.new_status);
                                    this.setState({mySolarlyboxes: temporal_mysolarlyboxes});
                                    break;
                                }
                            }
                        }
                        if (this.hasPendingPayGOperation(paygHistory)) {
                            this.pollPayGState(5000);
                        }

                    });

                })
                .catch(err => {
                    console.log(err);
                    if (this.hasPendingPayGOperation(this.state.paygHistory)) {
                        this.pollPayGState(5000);
                    }
                });
        }
    }

    counter = 1;
    // this calls getPaygHistory after 5seconds to update the PayG table
    // getPaygHistory inturn makes a call to pollPayGstate after the request which calls it again (some sort of recursive)
    // polling is done if an only if there is a pending PayG operation
    pollPayGState(time) {
        //console.log("polling the message");
        setTimeout(this.getPaygHistory, time);
    }

    applyPaygChange(modalData) {
        const solarlyboxId = this.state.mySolarlyboxes[this.state.solarlybox].id
        const url = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/solarlyboxes/${solarlyboxId}/payg_history`;

        // Translate strings into their representative ints
        const postData = {
            'new_status': Object.values(paygStatusLookup).indexOf(modalData['new_status']),
            'old_status': Object.values(paygStatusLookup).indexOf(modalData['old_status']),
            'old_payg_dl': modalData['old_payg_dl'],
            'new_payg_dl': modalData['new_payg_dl'],
            'comment': modalData['comment'],
            'change_type': modalData['change_type']
        }
        console.log(modalData['change_type'])
        axios.post(url, postData)
            .then(res => {
                //push to the top after update rather than bottom
                let newPayGHistory = [];
                newPayGHistory.push(this.processPaygChangeData(res.data.payg_change));

                let paygHistory = newPayGHistory.concat(this.state.paygHistory);
                this.setState({paygHistory: paygHistory}, () => {
                    if (this.hasPendingPayGOperation(paygHistory)) {
                        this.pollPayGState(5000);
                    }
                })
            })
            .catch(err => console.log(err))

        this.setState({'showPaygModal': false})
    }

    hasPendingPayGOperation(paygHistory) {
        for (let cter = paygHistory.length - 1; cter >= 0; cter--) {
            let historyItem = paygHistory[cter];
            if (historyItem.result === "PENDING") {
                return true;
            }
        }
        return false;
    }

    // function that allow to set the element solarlybox and to go to the detail of the box
    onClickRow(e) {
        const row = Number(e.currentTarget.id); // if from the tr element
        const idx = this.state.mySolarlyboxes.findIndex(item => item.id === row); // index of the element in mysolarlybox
        this.setState({query: ""});
        // https://stackoverflow.com/questions/42992394/how-do-i-programaticly-navigate-to-a-route-from-an-event-handler-in-react-router
        this.setState({solarlybox: idx},
            this.props.history.push('/mysolarlyboxes/' + (e.currentTarget.id)));
        this.loadData();

    }
    // function that set the new value for the number of row per page
    onChangeOption(e) {
        this.setState({rowsPerPage: e.currentTarget.value})
        this.paginate(1);
    }

    loadData() {
        this.setState({
            selectedSolarlyboxData: {},
            loadingSolarlyBoxData: true
        }, () => {
            this.getSolarlyBoxInformation();
        });
    }

    //added solarly box information
    getSolarlyBoxInformation() {

        const base_url = process.env.REACT_APP_BACKEND_SERVICE_URL;
        const selectedSolarlybox = this.state.mySolarlyboxes[this.state.solarlybox];

        const fetch_url = base_url + '/influx_data/data/' + selectedSolarlybox.id;
        axios.get(fetch_url).then(resp => {

            this.setState({
                selectedSolarlyboxData: resp.data,
                loadingSolarlyBoxData: false
            });

        }).catch(e => {
            this.setState({
                loadingSolarlyBoxData: false,
                errorLoadingSolarlyBoxData: true
            });
        });
    }

    // verify if you can change the PayG status
    verifyPayGChangeable() {
        this.setState({verifyingPayGChangeable: true});
        const baseUrl = process.env.REACT_APP_BACKEND_SERVICE_URL
        //status array contains the payg tests text and the status
        let status_array = [];
        //step1 : check if the payg change is okay
        let hasPendingPayg = this.hasPendingPayGOperation(this.state.paygHistory);
        status_array.push({
            text: "Has no pending PayG change",
            status: !hasPendingPayg
        });
        let connection_status = false;
        //const sim_url = `${baseUrl}/sims/${slybox.id}`;

        let slybox = this.state.mySolarlyboxes[this.state.solarlybox];
        axios.get(`${baseUrl}/sims/${slybox.id}`).then(resp => {
            connection_status = resp.data.status == "success." && resp.data.sim && resp.data.sim.connectivity_status === "ONLINE";
            status_array.push({
                text: "Connection to SolarlyBox",
                status: connection_status
            });
            this.setState({
                canUpdatePayG: connection_status && !hasPendingPayg,
                PayGConditions: status_array,
                verifyingPayGChangeable: false
            });
        }).catch(e => {
            status_array.push({
                text: "Connection to SolarlyBox",
                status: false
            });
            this.setState({
                canUpdatePayG: connection_status && !hasPendingPayg,
                PayGConditions: status_array,
                verifyingPayGChangeable: false
            });
        });

    }

    setBreadcrumbProps() {
        let url;
        let location;

        if (this.props.location.pathname !== "/mysolarlyboxes" && this.state.solarlybox !== null) {
            const selectedSolarlybox = this.state.mySolarlyboxes[this.state.solarlybox];
            url = "./mysolarlyboxes./mysolarlyboxes/" + selectedSolarlybox.id;
            location = 'General.My SolarlyBox.' + selectedSolarlybox.id;
        } else {
            url = "./mysolarlyboxes";
            location = 'General.My SolarlyBox';
        }
        return {url, location}
    }
    // function that set the value of the filter
    onFilterAction(evt) {
        evt.preventDefault();
        this.setState({query: this.state.querys})
        this.paginate(1)
    }
    // function that search for the right row
    search(data) {
        let keys = [];
        Object.entries(ColumnSelectBox).map(entry => {
            if (ColumnSelectBox[entry[0]]) {
                keys.push(entry[0])
            }
        });

        return data.filter((item) => keys.some((key) =>
            typeof (item[key]) === ("string" || "number") && item[key].toLowerCase().includes(this.state.query)
        ));
    }
    // function that set the value of the current page
    paginate(pageNumber) {
        this.setState({currentPage: pageNumber});
        this.getSolarlyboxes()
    }

    render() {
        //testing the string to see if the pathname follows the regular expression below.
        let test_regexp = /\/mysolarlyboxes((\/[0-9]+((\/config|\/payg|\/analysis)|\/|$))|\/|$)/;
        let urlStateMismatch = !test_regexp.test(this.props.location.pathname);
        const {url, location} = this.setBreadcrumbProps();
        let solarlyboxId;
        let solarlyboxName;
        if (this.state.solarlybox !== null) {
            solarlyboxId = this.state.mySolarlyboxes[this.state.solarlybox].id
            solarlyboxName = this.state.mySolarlyboxes[this.state.solarlybox].box_name
        }

        // Todo : commenter le code et nettoyer et refactorer

        const indexOfLastRow = this.state.currentPage * this.state.rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - this.state.rowsPerPage;

        return (
            <React.Fragment>
                <ToastContainer autoClose={7000}/>
                {urlStateMismatch &&
                <Redirect to="/mysolarlyboxes"/>
                }
                {!urlStateMismatch &&
                <div data-cy="mysolarlyboxes-page" className="column">
                    <div className="container">
                        <Breadcrumbs
                            location={location}
                            url={url}
                            paginate={this.paginate}
                            currentPage={this.state.currentPage}
                        />
                        {this.state.loadingPage &&
                        <LoadingView/>
                        }
                        {!this.state.loadingPage &&
                        <section className="section">
                            <Switch>
                                <Route exact path="/mysolarlyboxes" render={() =>
                                    <React.Fragment>
                                        <h1 className="title is-2">
                                            My SolarlyBox
                                        </h1>
                                        <form onSubmit={this.onFilterAction}>
                                            <div className="field is-grouped">
                                                <div className='control'>
                                                    <input type='text' placeholder="Search..." className="input is-warning" onChange={(e) => this.setState({querys: e.target.value})}/>
                                                </div>
                                                <div className='control'>
                                                    <button type='submit' className="button is-warning">
                                                        <span className="icon is-small">
                                                            <i className="fas fa-search"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <ListTable
                                            check={true}
                                            data-testid="solarlyboxes-table"
                                            tableData={this.search(this.state.mySolarlyboxes)} // table of the mySolarlyboxes with the filter search
                                            onClickRow={this.onClickRow}
                                            selectableStyle="is-selectable"
                                            selectedColumn={true}
                                            columnSelect={ColumnSelectBox}
                                            paginationSelect={true}
                                            indexOfLastRow={indexOfLastRow}
                                            indexOfFirstRow={indexOfFirstRow}
                                            ColumnSelectDescription = {ColumnSelectBoxDescription}
                                        />
                                        <Pagination
                                            rowsPerPage={this.state.rowsPerPage} // value of current for element per page
                                            rowsTotal={this.search(this.state.mySolarlyboxes).length} // total number of row
                                            paginate={this.paginate}
                                            numberCurrentPage={this.state.currentPage} // number of the current page
                                            onChangeOption={this.onChangeOption} // get the new value for element per page
                                            defaultValueOption={this.state.defaultValueOption} // default value for element per page
                                            lastDefaultValueOption={this.state.lastDefaultValueOption}
                                        />
                                    </React.Fragment>
                                }/>
                                <Route path="/mysolarlyboxes/:id" render={() =>
                                    <React.Fragment>
                                        <h1 className="title is-2">
                                            {'SolarlyBox : ' + solarlyboxName}
                                        </h1>
                                        <SlyTabs
                                            solarlyboxId={solarlyboxId}
                                            callbacks={{
                                                details: () => {
                                                },
                                                config: this.getConfig,
                                                payg: this.getPaygHistory,
                                            }}
                                            data-cy="mysolarlyboxes-tabs"/>

                                        {this.props.location.pathname === "/mysolarlyboxes/" + solarlyboxId &&
                                        <OneColTable
                                            data-cy="mysolarlyboxes-details"
                                            data={this.state.mySolarlyboxes[this.state.solarlybox]}/>
                                        }
                                        {this.props.location.pathname === "/mysolarlyboxes/" + solarlyboxId + "/config" &&
                                        <ConfigTable config={this.state.config}/>
                                        }
                                        {this.props.location.pathname === "/mysolarlyboxes/" + solarlyboxId + "/payg" &&
                                        <React.Fragment>
                                            {this.state.showPaygModal &&
                                            <PaygModal
                                                today={moment()}
                                                currentDeadline={this.state.paygDl}
                                                currentStatus={this.state.mySolarlyboxes[this.state.solarlybox].payg}
                                                onSubmit={this.applyPaygChange}
                                                onQuit={() => this.setState({showPaygModal: false})}
                                                // todo : for prod replace by this.state.canUpdatePayG
                                                // todo : for dev replace by true
                                                changeable={this.state.canUpdatePayG}
                                                // todo : for prod replace by this.state.paygConditions
                                                // todo : for dev replace by this.paygConditions
                                                conditions={this.state.PayGConditions}
                                                verifyingPayG={this.state.verifyingPayGChangeable}
                                            />
                                            }
                                            <ActionButton
                                                text={"Change PayG Status"}
                                                onClick={() => {
                                                    this.verifyPayGChangeable();
                                                    this.setState({showPaygModal: true})
                                                }}/>
                                            <ListTable tableData={this.state.paygHistory} headerCol={false}
                                                       selectedColumn={false}/>
                                        </React.Fragment>
                                        }
                                        {this.props.location.pathname === "/mysolarlyboxes/" + solarlyboxId + "/analysis" &&
                                        <AnalysisPage state={this.state} solarlyboxid={solarlyboxId}/>
                                        }


                                    </React.Fragment>
                                }/>

                                {/*/>*/}
                            </Switch>
                        </section>
                        }
                    </div>
                </div>
                }
            </React.Fragment>
        )
    }
}

// https://stackoverflow.com/questions/42992394/how-do-i-programaticly-navigate-to-a-route-from-an-event-handler-in-react-router
// I can still reference my object as MySolarlyboxesTable
const MySolarlyboxesPageWithRouter = withRouter(MySolarlyboxesPage);
export default MySolarlyboxesPageWithRouter;
