import React from 'react';

const Login = function(props) {
  return (
    <form onSubmit={props.onSubmit}>
      <div className="field">
        <p className="control has-icons-left">
          <input
            name="email" 
            className="input is-medium" 
            type="email" 
            placeholder="Email"
            value={props.formData.email}
            onChange={props.onChange}/>
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
        </p>
      </div>
      <div className="field">
        <p className="control has-icons-left">
          <input
            name="password" 
            className="input is-medium" 
            type="password" 
            placeholder="Password"
            value={props.formData.password}
            onChange={props.onChange}/>
          <span className="icon is-small is-left">
            <i className="fas fa-lock"></i>
          </span>
        </p>
      </div>
      <input
        type="submit"
        className="button is-primary is-medium is-fullwidth"
        value="Submit"
        />
    </form>
    )
}

export default Login;