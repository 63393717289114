import React from 'react';
import axios from 'axios';

import Breadcrumbs from './Breadcrumbs'
import OneColTable from './OneColTable'
import WelcomeMsg from './WelcomeMsg'

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.state = {
      profile : {},
    }
  }
  componentDidMount(){
    this.getProfile();
  }
  getProfile() {
    const url = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/users/${this.props.userId}`;
    axios.get(url)
      .then(res => {
        this.setState({ profile: res.data.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
    render() {
        return ( 
            <div data-cy="home-page" className="column">
              <div className="container">
                <Breadcrumbs 
                  location='General.Dashboard'
                  url="./"
                  data-cy="home-breadcrumb"/>
                <WelcomeMsg />
                <OneColTable 
                data-cy="home-profile"
                data={this.state.profile}/>
              </div>
            </div>
            )
    }
}

export default HomePage;