import React from 'react';

/**
 * { rowsPerPage, rowsTotal, paginate, numberCurrentPage, onChangeOption, defaultValueOption }
 * rowsPerPage = number of row per page
 * rowsTotal = number of total row
 * paginate = function that change the value of the current page
 * numberCurrentPage = number of the current page
 * onChangeOption = function that change the value of row per page
 * defaultValueOption = the default value of row per page
 */
const Pagination = ({rowsPerPage, rowsTotal, paginate, numberCurrentPage, onChangeOption, defaultValueOption}) => {
    const pageNumbers = [];
    // loop that store all the number of page in the table pageNumbers
    for (let i = 1; i <= Math.ceil(rowsTotal / rowsPerPage); i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {

        if (numberCurrentPage-4 < number && numberCurrentPage+4 > number) {
            return (
                <li
                    key={number}
                    className={numberCurrentPage === number ? "active" : null}
                >
                    <a onClick={() => paginate(number)} className={numberCurrentPage === number ? "pagination-link active is-current" : "pagination-link"}>
                        {number}
                    </a>
                </li>
            );
        } else {
            return null;
        }
    });
    // function that change the value of the current page + 1
    const handleNextbtn = () => {
        paginate(numberCurrentPage + 1);
    };
    // function that change the value of the current page - 1
    const handlePrevbtn = () => {
        paginate(numberCurrentPage - 1);
    };


    return (
        <nav className="pagination is-centered" role="navigation" aria-label="pagination">
            <ul className='pagination'>
                {numberCurrentPage !== 1 && <li><a className='pagination-previous' onClick={handlePrevbtn}>Previous page</a></li>}
                {renderPageNumbers}
                {pageNumbers.length !== numberCurrentPage && <li><a className='pagination-next' onClick={handleNextbtn}>Next page</a></li>}

                <div className='select is-warning'>
                    <select onChange={onChangeOption} defaultValue={rowsPerPage}>
                        <option value={defaultValueOption}>{defaultValueOption}</option>
                        <option value={defaultValueOption + 5}>{defaultValueOption + 5}</option>
                        <option value={defaultValueOption + 10}>{defaultValueOption + 10}</option>
                        <option value={defaultValueOption + 20}>{defaultValueOption + 20}</option>
                    </select>
                </div>
            </ul>
        </nav>
    )
}

export default Pagination;