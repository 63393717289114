import React from 'react';

function formatKey(key){
  let parts = key.split('_');
  return parts
            .map(el => el[0].toUpperCase() + el.slice(1))
            .join(' ')
}

const OneColTable = function(props) {
	return (
		<div data-cy={props['data-cy']}>
      <table className="table">
        <tbody>
        {
          Object.keys(props.data).map( (key,i) => {
            return (
            <tr key={i}>
              <th>{formatKey(key)}</th>
              <td>{props.data[key]}</td>
            </tr>
            )
          })
        }
        </tbody>
      </table>  
    </div>
        )
}

export default OneColTable;
