import React from 'react';
import {Link, withRouter} from 'react-router-dom';

const SlyTabs = function ({
                              location,
                              solarlyboxId,
                              callbacks = {
                                  details: () => {
                                  },
                                  config: () => {
                                  },
                              },
                              ...rest
                          }) {
    let tabsClass = {
        details: 'is-active',
        config: '',
        payg: '',
        analysis: '',
    }

    if (location.pathname === "/mysolarlyboxes/" + solarlyboxId + "/config") {
        tabsClass.config = 'is-active';
        tabsClass.details = '';
        tabsClass.payg = '';
        tabsClass.analysis = '';
    }
    else if (location.pathname === "/mysolarlyboxes/" + solarlyboxId + "/payg") {
        tabsClass.payg = 'is-active';
        tabsClass.details = '';
        tabsClass.config = '';
        tabsClass.analysis = '';
    }
    else if (location.pathname === "/mysolarlyboxes/" + solarlyboxId + "/analysis") {
        tabsClass.payg = '';
        tabsClass.details = '';
        tabsClass.config = '';
        tabsClass.analysis = 'is-active';
    }

    return (
        <div className="tabs" data-cy={rest['data-cy']}>
            <ul>
                <li className={tabsClass.details} onClick={callbacks.details}>
                    <Link to={'/mysolarlyboxes/' + solarlyboxId}>
                        Details
                    </Link>
                </li>
                <li className={tabsClass.config} onClick={callbacks.config}>
                    <Link to={'/mysolarlyboxes/' + solarlyboxId + "/config"}>
                        Configuration
                    </Link>
                </li>
                <li className={tabsClass.payg} onClick={callbacks.payg}>
                    <Link to={'/mysolarlyboxes/' + solarlyboxId + "/payg"}>
                        PayG Status
                    </Link>
                </li>
                <li className={tabsClass.analysis} onClick={callbacks.analysis}>
                    <Link to={'/mysolarlyboxes/' + solarlyboxId + "/analysis"}>
                        Analysis
                    </Link>
                </li>


            </ul>
        </div>
    )
};
const SlyTabsWithRouter = withRouter(SlyTabs);
export default SlyTabsWithRouter;
