import React from 'react';


const formCol1 = {
  'Company Details': [
    {
      label: 'Name',
      name: 'company_name'
    },
    {
      label: 'VAT Number',
      name: 'company_VAT'
    },
    {
      label: 'Country',
      name: 'company_country'
    }
  ],
  'Billing Address': [
    {
      label: 'Address (street + nr)',
      name: 'billing_address'
    },
    {
      label: 'City',
      name: 'billing_city'
    },
    {
      label: 'Postal Code',
      name: 'billing_postal_code'
    },
    {
      label: 'State',
      name: 'billing_state'
    },
    {
      label: 'Country',
      name: 'billing_country'
    }
  ]
}
const formCol2 = {
    'Contact Details': [
    {
      label: 'Name',
      name: 'contact_name'
    },
    {
      label: 'Email',
      name: 'contact_email'
    },
    {
      label: 'Mobile Phone',
      name: 'contact_mobile_phone'
    },
    {
      label: 'Phone',
      name: 'contact_phone'
    }
  ],
'Contact Address': [
    {
      label: 'Address (street + nr)',
      name: 'contact_address'
    },
    {
      label: 'City',
      name: 'contact_city'
    },
    {
      label: 'Postal Code',
      name: 'contact_postal_code'
    },
    {
      label: 'State',
      name: 'contact_state'
    },
    {
      label: 'Country',
      name: 'contact_country'
    }
  ]
}
const NewClientForm = function(props) {
  return (
<form onSubmit={props.onSubmit}>
  <div className="columns">
    <div className="column">
      {
      Object.keys(formCol1).map((key,i) => {
        return (
            <React.Fragment key={i}>
            <h1 className="title is-3">{key}</h1>
            { formCol1[key].map((el,j) => 
                  <div key={j} className="field">
                    <label className="label">{el.label}</label>
                    <div className="control">
                      <input onChange={props.onChange} className="input" type="text" placeholder="" name={el.name}/>
                    </div>
                  </div>)
            }
            </React.Fragment >)
          })
        }
    </div>
    <div className="column">
      {
      Object.keys(formCol2).map((key,i) => {
        return (
            <React.Fragment key={i}>
            <h1 className="title is-3">{key}</h1>
            { formCol2[key].map((el,j) => 
                  <div key={j} className="field">
                    <label className="label">{el.label}</label>
                    <div className="control">
                      <input onChange={props.onChange} className="input" type="text" placeholder="" name={el.name}/>
                    </div>
                  </div>)
            }
            </React.Fragment >)
          })
        }
    </div>  </div>
  <input
    type="submit"
    className="button is-primary is-medium is-fullwidth"
    value="Submit"/>
  <br/>
</form>
    )
}

export default NewClientForm;
