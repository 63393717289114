import React from 'react';
import AnalysisXY from './AnalysisXY';
import AnalysisGauge from './AnalysisGauge';
import AnalysisPageError from './AnalysisPageError';
import LoadingView from './LoadingView';
class AnalysisPage extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        let solarlyboxId =  this.props.solarlyboxid;
        let is_data_available = this.props.state.selectedSolarlyboxData && this.props.state.selectedSolarlyboxData.a_production && this.props.state.selectedSolarlyboxData.a_production.length > 0 && !this.props.state.loadingSolarlyBoxData;
        let has_no_stats =this.props.state.selectedSolarlyboxData && (!this.props.state.selectedSolarlyboxData.a_consumption || !this.props.state.selectedSolarlyboxData.a_consumption.length) && !this.props.state.loadingSolarlyBoxData && !this.props.state.errorLoadingSolarlyBoxData;
        let is_loading =this.props.state.loadingSolarlyBoxData;
        let error_occurred=!this.props.state.selectedSolarlyboxData || this.props.state.errorLoadingSolarlyBoxData;
        return (
            <React.Fragment>
                              {/*testing on the a_production data ... if present ... the others are present as well */}
                              {is_data_available &&
                              <div className="columns is-multiline">
                                  <AnalysisXY
                                      id="production_vs_consumption"
                                      testId="production_vs_consumption"
                                      title="Production vs Consumption [Wh]"
                                      solarlybox_id={solarlyboxId}
                                      data={this.props.state.selectedSolarlyboxData.powers}
                                      field1="ps"
                                      field2="pc"
                                      field2Name="Consumption"
                                      field1Name="Production" />
                                  <AnalysisXY
                                      id="temperature_min_max"
                                      testId="temperature_min_max"
                                      title="Temperature evolution [°C]"
                                      field1Name="Temperature Max"
                                      field2Name="Temperature Min"
                                      solarlybox_id={solarlyboxId}
                                      data={this.props.state.selectedSolarlyboxData.temperature}
                                      field1="t_max"
                                      field2="t_min"/>
                                  <AnalysisXY
                                      id="battery_min_max"
                                      testId="battery_min_max"
                                      title="Battery voltage [V]"
                                      solarlybox_id={solarlyboxId}
                                      data={this.props.state.selectedSolarlyboxData.voltage}
                                      field1="ub_max" field2="ub_min"
                                      field1Name="Battery voltage Max"
                                      field2Name="Battery voltage Min"/>
                                  <AnalysisXY
                                      id="soc_min_max"
                                      testId="soc_min_max"
                                      title="State of charge "
                                      solarlybox_id={solarlyboxId}
                                      data={this.props.state.selectedSolarlyboxData.soc}
                                      field1="soc_min"
                                      field2="soc_max"
                                      field1Name="State of Charge Min"
                                      field2Name="State of Charge Max"/>
                                  <AnalysisXY
                                      id="communication_xy"
                                      testId="communication_xy"
                                      title="Communication"
                                      solarlybox_id={solarlyboxId}
                                      data={this.props.state.selectedSolarlyboxData.communication}
                                      field1="communication"
                                      field1Name="Communication" />
                                  <AnalysisGauge
                                      id="actual_consumption"
                                      testId="actual_consumption"
                                      title="Actual Consumption"
                                      solarlybox_id={solarlyboxId}
                                      value={this.props.state.selectedSolarlyboxData.a_consumption[this.props.state.selectedSolarlyboxData.a_consumption.length-1].last_pc}
                                      max_value={100}
                                      numberFormat={"#.#'W'"}/>
                                   <AnalysisGauge
                                       id="actual_production"
                                       testId="actual_production"
                                       title="Actual Production"
                                       solarlybox_id={solarlyboxId}
                                       value={this.props.state.selectedSolarlyboxData.a_production[this.props.state.selectedSolarlyboxData.a_consumption.length-1].last_ps}
                                       max_value={100}
                                       numberFormat={"#.#'W'"}/>
                                  <AnalysisGauge
                                      id="actual_soc"
                                      testId="actual_soc"
                                      title="Actual SoC"
                                      solarlybox_id={solarlyboxId}
                                      value={this.props.state.selectedSolarlyboxData.a_soc[this.props.state.selectedSolarlyboxData.a_soc.length-1].last_soc}
                                      max_value={100}
                                      numberFormat={"#.#'%'"}/>
                                  <AnalysisGauge
                                      id="actual_temperature"
                                      testId="actual_temperature"
                                      title="Actual Temperature"
                                      solarlybox_id={solarlyboxId}
                                      value={this.props.state.selectedSolarlyboxData.a_temperature[this.props.state.selectedSolarlyboxData.a_temperature.length-1].last_t}
                                      max_value={100}
                                      numberFormat={"#.#'°C'"}/>
                              </div>
                              }
                              {has_no_stats &&
                                  <AnalysisPageError errorText="No Stats available for now..."/>
                              }
                              {is_loading &&
                                    <LoadingView message="Loading Analytics..."/>

                            }
                            {error_occurred &&
                                  <AnalysisPageError errorText="An unexpected error occurred ..."/>

                              }


                          </React.Fragment>
        )
    }
}
export default AnalysisPage;
