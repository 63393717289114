import React ,{useState,useEffect} from 'react';
import loaderGif from '../assets/loader.gif';
import axios from "axios";

const LoadingSpinner= ({maxSize, id, solarlyboxOverviewData, baseUrl}) =>{

    /*const sim_url = `${baseUrl}/sims/${id}`;
    const getDataPromises = () => {

                    return Promise.all([axios.get(sim_url)])
                };

                Promise
                    .all(getDataPromises)
                    .then(responses => {
                        if (this.isUnmounted) {
                            return;
                        }
                        let solarlyboxOverviewData = solarlyboxOverviewData.map((slybox, i) => {
                            const {status, connectivity_status, ...restSim} = responses[i][0].data.sim
                            slybox['sim_status'] = status
                            slybox['connectivity_status'] = connectivity_status
                            return slybox
                        })
                        //this.setState({mySolarlyboxes: solarlyboxOverviewData});

                    })
                    .catch(err => {
                        let solarlyboxOverviewData = solarlyboxOverviewData.map((slybox, i) => {
                            slybox['sim_status'] = 'ERR'
                            slybox['connectivity_status'] = 'ERR'
                            return slybox
                        })
                        this.setState({mySolarlyboxes: solarlyboxOverviewData});

                    })*/
    return (
        <img data-testid="loading-gif" src={loaderGif} alt="Loading..." className="loadingGif" style={{maxHeight:maxSize,maxWidth: maxSize}}/>

    );
}
export default LoadingSpinner;