import React from 'react';
import logo from '../logo.svg'

const WelcomeMsg = function(props) {
	return (
		<section className="hero is-small">
            <div className="hero-body">
                <div className = "columns">
                  <div className = "column is-1">
                    <figure className="image is-64x64">
                      <img alt="" src={logo} />
                    </figure>
                  </div>
                  <div className="column is-11">
                    <h1 className="title">
                        Bienvenue sur la plateforme SLY de Solarly !
                    </h1>
                    <h2 className="subtitle">
                        Welcom on the SLY platform of Solarly !
                    </h2>
                  </div>
                </div>
            </div>
        </section>
        )
}

export default WelcomeMsg;