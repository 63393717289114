
export const paygStatusLookup = {
	0: "OK",
	1: "WILL EXPIRE",
	2: "LOCK"
}

export const paygResultLookup = {
	0: "FAILURE",
	1: "SUCCESS",
	2: "PENDING"
}

export const paygResultIconLookup = {
	0: '/payg-indicators/f.png',
	1: '/payg-indicators/s.png',
	2: '/payg-indicators/p.png',
}

export const paygStatusIconLookup = {
	0: '/payg-indicators/g.png',
	1: '/payg-indicators/y.png',
	2: '/payg-indicators/r.png',
}

export const paygChangeTypeLookup = {
	0: "Status",
	1: "DeadLine",
}