import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import './AnalysisXY.scss';

am4core.useTheme(am4themes_animated);

class AnalysisGauge extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

        let chart = am4core.create(this.props.id, am4charts.RadarChart);
        /*
       *  Data is received and chart data is prepared
       * */
        let json_ = this.props.data;
        // Add data
        chart.data = [
            {
                "category": this.props.title,
                "value": this.props.value,
                "full": this.props.max_value
            }
            /*{
                "category": "Actual Temperature [°C]",
                "value": json_.t,
                "full": 100
            },
            {
                "category": "Actual production [W]",
                "value": json_.ps,
                "full": 100
            },
            {
                "category": "Actual Soc",
                "value": json_.soc,
                "full": 100
            }*/];

        // Make chart not full circle
        chart.startAngle = -90;
        chart.endAngle = 180;
        chart.innerRadius = am4core.percent(20);

        // Set number format
        chart.numberFormatter.numberFormat = this.props.numberFormat;

        // Create axes
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.fontWeight = 500;
        categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
            return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
        });
        categoryAxis.renderer.minGridDistance = 10;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;

        // Create series
        let series1 = chart.series.push(new am4charts.RadarColumnSeries());
        series1.dataFields.valueX = "full";
        series1.dataFields.categoryY = "category";
        series1.clustered = false;
        series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        series1.columns.template.fillOpacity = 0.08;
        series1.columns.template.cornerRadiusTopLeft = 20;
        series1.columns.template.strokeWidth = 0;
        series1.columns.template.radarColumn.cornerRadius = 20;

        let series2 = chart.series.push(new am4charts.RadarColumnSeries());
        series2.dataFields.valueX = "value";
        series2.dataFields.categoryY = "category";
        series2.clustered = false;
        series2.columns.template.strokeWidth = 0;
        series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
        series2.columns.template.radarColumn.cornerRadius = 20;

        series2.columns.template.adapter.add("fill", function (fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });

        // Add cursor
        chart.cursor = new am4charts.RadarCursor();
        //return this.chart = chart;
    }

    render() {
        return (
                <div className="is-pulled-left is-half column" data-testid={this.props.testId}>
                     <div id={this.props.id}  className="is-fullwidth is-500px-height"/>
                    <center><h5><b>{this.props.title}</b></h5></center>
                </div>
        );
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}

export default AnalysisGauge;