import React from 'react';

import './Nav.scss';
import logoText from '../logo_text.svg'

const Nav = function(props) {
  return (
    <nav data-cy={props['data-cy']} className="navbar navbarBorder">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img alt="Solarly Logo" src={logoText} width="112" height="28"/>
        </a>
      </div>
      <div id="navMenu" className="navbar-menu is-active">
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <button onClick={props.onLogout} className="button is-light">
                Log out
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    )
}

export default Nav;