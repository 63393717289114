import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import './AnalysisXY.scss';

am4core.useTheme(am4themes_animated);

class AnalysisXY extends React.Component {
    constructor(props) {
        super(props);

        /*
        * Data received this.props.data
        * */
    }

    componentDidMount() {

        let chart = am4core.create(this.props.id, am4charts.XYChart);
        //    rest of the logic
        chart.paddingRight = 20;
        //

        //receive the chart data which was sent as a property. get the DateTime string and parse into Date Object
        const json_ = this.props.data;
        for (let i = 0; i < json_.length; i++) {
            json_[i].date = new Date(json_[i].time);
        }

        //fix this data to the chart
        chart.data = json_;

        //preparing the date axis and the precision
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0.001;//0.0001;
        dateAxis.baseInterval = {
            "timeUnit": "day",
            "count": 1
        };

        /*
        * Preparing value axis 1 [and possibly two in case of a "dual graph"]
        * 1. prepare axis
        * 2. prepare the series (with the tooltip and push it to the scroll bar)
        * 3. verify if there is a defined field2 property to get data from the chart.data
        * 4. do same as above
        * */
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = this.props.field1;

        series.tooltipText = this.props.field1Name +  ": {valueY.value}";
        chart.cursor = new am4charts.XYCursor();

         let scrollbarX = new am4charts.XYChartScrollbar();
         scrollbarX.series.push(series);


        if (this.props.field2) {
             let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.tooltip.disabled = true;
        valueAxis2.renderer.minWidth = 100;
            let series2 = chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.dateX = "date";
            series2.dataFields.valueY = this.props.field2;

            series2.tooltipText = this.props.field2Name + ": {valueY.value}";
            chart.cursor = new am4charts.XYCursor();
             scrollbarX.series.push(series2);
        }

         chart.scrollbarX = scrollbarX;
         chart.numberFormatter.numberFormat = "#.##";
         return this.chart = chart;
    }

    render() {
        return (
            <div className="is-pulled-left is-half column" data-testid={this.props.testId}>
                     <div id={this.props.id}  className="is-fullwidth is-500px-height"/>
                    <center><h5><b>{this.props.title}</b></h5></center>
            </div>
        );
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}

export default AnalysisXY;