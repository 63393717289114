import React, { useState } from 'react';

import { paygStatusLookup } from './lookups/paygLookup';
import loaderGif from '../assets/loader.gif';
import './PaygModal.scss';
import Switch from "./SwitchButton";
import moment from 'moment';

const PaygModal = function({today ,currentDeadline, currentStatus, onSubmit=(()=>{}), onQuit=(()=>{}), changeable=false, verifyingPayG=false, conditions=[]}) {

	// compute status change options
	let statusOptions = Object.values(paygStatusLookup)
	for( var i = 0; i < statusOptions.length; i++){
	   if ( statusOptions[i] === currentStatus) {
	     statusOptions.splice(i, 1); 
	   }
	}

	const handleChangeSwitch = () =>{
		setSwicthValue(!swicthValue)
	}

	const verifDate = (date) => {
		let now = moment().startOf('date');
		date = date.startOf('date');
		if(date.isAfter(now) || date.isSame(now)){
			return true;
		}
	}

	const handleChangeDeadline = (event) => {
		let newDate = moment(event.target.value);
		if(verifDate(newDate)){
			setNewPaygdDlInput(newDate)
		}
	}

	const [newPaygDlInput, setNewPaygdDlInput] = useState(today)
	const [newStatusInput, setNewStatusInput] = useState(statusOptions[0])
	const [commentInput, setCommentInput] = useState('')
	const [swicthValue, setSwicthValue] = useState(false)

  return (
    <div data-testid="modal" className="modal is-active">
    	<div className="modal-background"></div>
		<div className="modal-card">
		  <header className="modal-card-head">
		    <p className="modal-card-title">Change PayG</p>
		    <button onClick={onQuit} data-testid="close" className="delete" aria-label="close"></button>
		  </header>
		    <section className="modal-card-body payg-bb">
			      <div className="field payg-left">
					  <p className={swicthValue?"payg-disabled":""}><strong>Current Status: </strong>{currentStatus}</p>
			        <label className="label">Change PayG to</label>
			        <div className="control">
			          <div className="select">
			            <select
			            	value={newStatusInput}
			            	onChange={event => setNewStatusInput(event.target.value)}
							disabled={swicthValue}
			            	data-testid="optionsSelect">
		            		{
		            			statusOptions.map((option, i) => {
		            				return (<option key={i}>{option}</option>)
		            			})
		            		}
			            </select>
			          </div>
			        </div>
			      </div>
					<div className="field payg-middle" >
					<Switch
        				isOn={swicthValue}
        				handleToggle={() => handleChangeSwitch()}
      				/>
				</div>
				  <div className="field payg-left" >
					<p className={!swicthValue?"payg-disabled":""}><strong>Current deadline: </strong>{currentDeadline ? moment.unix(currentDeadline).format('DD[-]MM[-]YYYY') : "None"}</p>
			        <label className="label" >Change deadline </label>
			        <div className="control">
			          <div className="field">
						  <input
							  className="input border"
							  type="date"
							  id="payg_dl"
							  name="payg_dl"
							  value={newPaygDlInput.format('YYYY[-]MM[-]DD')}
							  onChange={event => handleChangeDeadline(event)}
							  disabled={!swicthValue}
							  min={today.format('YYYY[-]MM[-]DD')}
						  />

			          </div>
			        </div>
			      </div>
			      <div className="field">
			        <label className="label">Comment</label>
			        <div className="control">
			          <textarea
			            className="textarea"
			            maxLength="150"
			            value={commentInput}
			            onChange={event => setCommentInput(event.target.value)}
			            placeholder="Why are you changing the PayG?"></textarea>
			        </div>
			      </div>
				  <div>
					  {verifyingPayG && <span><img  src={loaderGif} alt="Verifying payg..." className="loading-gif" data-testid="verifying-payg"/> &nbsp;<span>Verifying PayG</span></span>}
					  {!verifyingPayG && <div>
						  <p><b>Conditions: </b></p>
						  <ul>
							  {console.log(conditions)}
							  {conditions.map((condition,i)=>{return(
							  <li key={"condition-"+i}>-<span>{condition.text}:</span>{condition.status && <img src="/payg-indicators/s.png" className="condition-icon" alt="payg-indicators-success" data-testid={"con-passed-"+i}></img>}
							  {!condition.status && <img src="/payg-indicators/f.png" alt="payg-indicators-failed" className="condition-icon" data-testid={"con-failed-"+i}></img>}</li>
						  );})}
						  </ul>
						  <br/>
						  <p>NB: If one of the conditions above are not met, it is not possible to change the PayG status</p>
						  </div>}
				  </div>
			  </section>
			  <footer className="modal-card-foot">
				<button 
					disabled={!changeable||verifyingPayG}
					onClick={event => {
						event.preventDefault()
						onSubmit({
							'old_status': swicthValue ? null : currentStatus,
							'new_status': swicthValue ? null : newStatusInput ,
							'old_payg_dl': swicthValue ? currentDeadline : null,
							'new_payg_dl': swicthValue ? newPaygDlInput.set({hour:0,minute:0,second:0,millisecond:0}).unix() : null ,
							'comment': commentInput,
							'change_type' : swicthValue ? 1 : 0
						})
					}} 
					className="button is-success">Apply</button>
				<button onClick={onQuit} className="button" >Cancel</button>
			</footer>
		</div>
    </div>
    )
}

export default PaygModal;
