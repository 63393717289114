import React from 'react';
import axios from 'axios';

import {Switch, Route, Redirect, withRouter} from 'react-router-dom';

import Breadcrumbs from './Breadcrumbs'
import ActionButton from './ActionButton'
import NewClientForm from './NewClientForm'
import ListTable from './ListTable'
import OneColTable from './OneColTable'
import {ColumnSelectClient, ColumnSelectClientDescription} from './lookups/ColumnSelect';

const formFields = {
    'company_name': '',
    'company_VAT': '',
    'company_country': '',
    'billing_address': '',
    'billing_city': '',
    'billing_postal_code': '',
    'billing_state': '',
    'billing_country': '',
    'contact_name': '',
    'contact_email': '',
    'contact_mobile_phone': '',
    'contact_phone': '',
    'contact_address': '',
    'contact_city': '',
    'contact_postal_code': '',
    'contact_state': '',
    'contact_country': '',
}

const displayFormat = {
    'Company Details': [
        'company_name',
        'company_VAT',
        'company_country'
    ],
    'Billing Address': [
        'billing_address',
        'billing_city',
        'billing_postal_code',
        'billing_state',
        'billing_country'
    ],
    'Contact Details': [
        'contact_name',
        'contact_email',
        'contact_mobile_phone',
        'contact_phone'
    ],
    'Contact Address': [
        'contact_address',
        'contact_city',
        'contact_postal_code',
        'contact_state',
        'contact_country'
    ]
}

class ClientsPage extends React.Component {
    constructor(props) {
        super(props);
        this.getClients = this.getClients.bind(this);
        this.newClientClick = this.newClientClick.bind(this);
        this.newClientFormChange = this.newClientFormChange.bind(this);
        this.newClientFormSubmit = this.newClientFormSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
        this.setClientDetailsData = this.setClientDetailsData.bind(this);
        this.setBreadcrumbProps = this.setBreadcrumbProps.bind(this);
        this.state = {
            isLoading: true,
            clientsData: [],
            clientsListData: [],
            client: null,
            newClientForm: formFields
        }
    }

    componentDidMount() {
        this.getClients();
    }

    componentDidUpdate() {
        if (this.state.isLoading) {
            this.getClients()
        }
        if (this.props.location.pathname === "/admin/clients" && this.state.client !== null) {
            this.setState({client: null});
        }
    }

    getClients() {
        const url = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/clients`;
        axios.get(url)
            .then(res => {
                const displayData = res.data.data.map(el => {
                    const {
                        id,
                        company_name,
                        company_slug,
                        company_country,
                        solarly_count,
                        status, ...rest
                    } = el; // eslint-disable-line
                    return {
                        id,
                        company_name,
                        company_slug,
                        company_country,
                        solarly_count,
                        status
                    }
                });
                this.setState({
                    clientsData: res.data.data,
                    clientsListData: displayData,
                    isLoading: false
                });
            })
            .catch(err => {
                console.log(err);
            })
    }

    newClientClick(event) {
        this.props.history.push('/admin/clients/new');
    }

    newClientFormChange(event) {
        const obj = this.state.newClientForm;
        obj[event.target.name] = event.target.value;
        this.setState({newClientForm: obj});
    }

    newClientFormSubmit(event) {
        event.preventDefault();
        const url = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/clients`;
        const data = this.state.newClientForm;
        axios.post(url, data)
            .then(res => {
                this.clearForm()
                this.props.history.push('/admin/clients');
                this.setState({
                    isLoading: true
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    clearForm() {
        this.setState({
            newClientForm: formFields
        })
    }

    onClickRow(e) {
        const row = e.currentTarget.rowIndex;
        const idx = row - 1;
        this.setState({client: idx},
            this.props.history.push('/admin/clients/' + this.state.clientsData[idx].company_slug))
    }

    setBreadcrumbProps() {
        let url = '/admin/clients./admin/clients';
        let location = 'Admin.Clients';

        if (this.state.client !== null) {
            if (this.props.location.pathname === "/admin/clients/" + this.state.clientsData[this.state.client].company_slug) {
                url = '/admin/clients./admin/clients./admin/clients/' + this.state.clientsData[this.state.client].company_slug;
                let company_name = this.state.clientsData[this.state.client].company_name
                location = 'Admin.Clients.' + company_name.replace('.', '');
            }
        } else if (this.props.location.pathname === "/admin/clients/new") {
            url = '/admin/clients./admin/clients./admin/clients/new';
            location = 'Admin.Clients.New';
        }
        return {url, location}
    }

    setClientDetailsData() {
        let displayData = {
            'Company Details': {},
            'Billing Address': {},
            'Contact Details': {},
            'Contact Address': {}
        }

        if (this.state.client !== null) {
            displayFormat['Company Details'].forEach((el, i) => {
                displayData['Company Details'][el] = this.state.clientsData[this.state.client][el]
            });
            displayFormat['Billing Address'].forEach((el, i) => {
                displayData['Billing Address'][el] = this.state.clientsData[this.state.client][el]
            });
            displayFormat['Contact Details'].forEach((el, i) => {
                displayData['Contact Details'][el] = this.state.clientsData[this.state.client][el]
            });
            displayFormat['Contact Address'].forEach((el, i) => {
                displayData['Contact Address'][el] = this.state.clientsData[this.state.client][el]
            });
        }
        return displayData
    }

    render() {
        // Handle Reload
        let urlStateMismatch = false;
        if (this.props.location.pathname !== "/admin/clients"
            && this.props.location.pathname !== "/admin/clients/new"
            && this.state.client === null) {
            urlStateMismatch = true;
        }
        // Set up breadcrumb props
        const {url, location} = this.setBreadcrumbProps()

        // Set up props for client Details OneColTables
        const displayData = this.setClientDetailsData()

        return (
            <>
                {urlStateMismatch &&
                <Redirect to="/admin/clients"/>
                }
                {!urlStateMismatch &&
                <div className="column">
                    <div className="container">
                        <Breadcrumbs
                            location={location}
                            url={url}
                            data-cy="clients-breadcrumb"/>
                        <Switch>
                            <Route exact path="/admin/clients" render={() =>
                                <>
                                    <ActionButton icon="plus" text="New Client" onClick={this.newClientClick}/>
                                    <section className="section">
                                        <h1 className="title is-2">Clients</h1>
                                        {!this.state.isLoading &&
                                        <ListTable
                                            tableData={this.state.clientsListData}
                                            onClickRow={this.onClickRow}
                                            selectableStyle="is-selectable"
                                            _render={this.renderSimpleTable}
                                            selectedColumn={true}
                                            columnSelect={ColumnSelectClient}
                                            ColumnSelectDescription={ColumnSelectClientDescription}
                                        />
                                        }
                                    </section>
                                </>}>
                            </Route>
                            <Route exact path="/admin/clients/new" render={() =>
                                <>
                                    <h1 className="title is-2">New Client</h1>
                                    <NewClientForm
                                        onChange={this.newClientFormChange}
                                        onSubmit={this.newClientFormSubmit}/>
                                </>}>
                            </Route>
                            <Route path="/admin/clients/:company_slug" render={() =>
                                <section className="section">
                                    <h1 className="title is-2">
                                        {this.state.clientsData[this.state.client].company_name}
                                    </h1>
                                    <div className="columns">
                                        <div className="columns column is-two-thirds">
                                            <div className="column">
                                                {Object.keys(displayFormat).slice(0, 2).map((el, i) =>
                                                    <React.Fragment key={i}>
                                                        <h1 className="subtitle is-3">{el}</h1>
                                                        <OneColTable
                                                            data={displayData[el]}/>
                                                    </React.Fragment>
                                                )
                                                }
                                            </div>
                                            <div className="column">
                                                {Object.keys(displayFormat).slice(2, 4).map((el, i) =>
                                                    <React.Fragment key={i}>
                                                        <h1 className="subtitle is-3">{el}</h1>
                                                        <OneColTable
                                                            data={displayData[el]}/>
                                                    </React.Fragment>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }/>
                        </Switch>
                    </div>
                </div>
                }
            </>
        )
    }

renderSimpleTable = (el, headerCol,key,j) => {
    if(j

===
    0
&&
    headerCol
) {
    return(

<th key= {
    j
}

>
{
    el[key]
}
</th>)
} else
{
    return (<td key={j}>{el[key]}</td>)
}
};
}
const ClientsPageWithRouter = withRouter(ClientsPage);
export default ClientsPageWithRouter;