import React from 'react';
import { Link } from 'react-router-dom';

import './SideMenu.scss';

const generalMenu = [
  {
    text: 'Dashboard',
    to: '/'
  },
  {
    text: 'My SolarlyBox',
    to: '/mysolarlyboxes'
  }
];
const adminMenu = [
  {
    text: 'Clients',
    to: '/admin/clients'
  }
];
const SideMenu = function(props) {
    return (
        <div data-cy={props['data-cy']} className="column is-2 is-sidebar-menu is-hidden-mobile">
          <aside className="menu">
            <p className="menu-label">
              General
            </p>
            <ul className="menu-list">
              {generalMenu.map((el,i) => 
                {
                  if (props.active === el.to) {
                    return (
                      <li key={i}>
                        <Link
                          to={el.to}
                          className="is-active">
                          {el.text}
                        </Link>
                      </li>)
                  }
                  if (props.active.startsWith(el.to) && el.to!=='/'){
                    return (
                      <li key={i}>
                        <Link
                          to={el.to}
                          className="is-active">
                          {el.text}
                        </Link>
                      </li>)
                    } 
                  return (
                    <li key={i}>
                      <Link
                        to={el.to}>
                        {el.text}
                      </Link>
                    </li>)
                  }
                )}
              </ul>
              { props.admin &&
                <>
                <p className="menu-label">
                  Admin
                </p>
                <ul className="menu-list">
                  {adminMenu.map((el,i) => 
                    {
                    if (props.active === el.to) {
                      return (
                        <li key={i}>
                          <Link
                            to={el.to}
                            className="is-active">
                            {el.text}
                          </Link>
                        </li>)
                    }
                    if (props.active.startsWith(el.to)){
                      return (
                        <li key={i}>
                          <Link
                            to={el.to}
                            className="is-active">
                            {el.text}
                          </Link>
                        </li>)
                      }
                    return (
                      <li key={i}>
                        <Link
                          to={el.to}>
                          {el.text}
                        </Link>
                      </li>)
                    }
                  )}
                </ul>
                </>
              }
        </aside>
      </div>
        )
}

export default SideMenu;
