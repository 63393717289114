import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = function(props) {
    const location = props.location.split('.');
    const url = props.url.split('.');
    return (
        <div 
          data-testid="breadcrumb"
          data-cy={props['data-cy']}>
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                {location.map( (el,i, a) => 
                  {
                    if (i !== a.length -1) {
                      return (<li key={i}><Link onClick={() => props.paginate(props.currentPage)} to={url[i]}>{el}</Link></li>)
                    }
                    else {
                      return (
                        <li key={i} className="is-active">
                          <Link to={url[i]} aria-current="page">{el}</Link>
                        </li>)
                    }
                  }
                )}
              </ul>
            </nav>
            <hr/>
        </div>
        )
};

export default Breadcrumbs;
