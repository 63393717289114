import React from 'react';

const FlashMessage = function({message, onClick}) {

  return (
      <div className="notification is-danger">
        <button className="delete" onClick={onClick} data-testid="delete"></button>
        {message}
      </div>
    )
}

export default FlashMessage;
