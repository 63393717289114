import React from 'react';

const supportedIcons = {
	'plus': (
		<span data-testid="icon" className="icon">
			<i className="fas fa-plus"></i>
		</span>)
}
const ActionButton = function({onClick, text, icon=null, style="is-info"}) {
	return (
		<button onClick={onClick} className={"button is-outlined "+ style } >
		{ 
			supportedIcons[icon]
		}
		<span>{text}</span>
		</button>)
}

export default ActionButton;


