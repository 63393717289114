import React from 'react';
import './AnalysisPageError.scss';

//component to manage error page on the analysis tab
const AnalysisPageError = ({errorText})=>{
        return (
            <>
            <h5>
                <center>
                    <br/>
                    <br/>
                    <img src="https://www.iconbunny.com/icons/media/catalog/product/1/6/1619.10-sad-icon-iconbunny.jpg"
                         className="errImg" title={errorText} data-testid="error_image"/>
                    <br/>
                    <b>{errorText}</b>
                </center>
            </h5>
            </>
        );
};
export default AnalysisPageError;