import React from 'react';
import loaderGif from '../assets/loader.gif';
const LoadingView= function({message}){
    return (
        <div>
            <div className="columns is-centered">
                <div className="column is-narrow">
                    <img data-testid="loading-gif" src={loaderGif} alt="Loading..." className="loadingGif"/>
                </div>
            </div>
            {message &&<div className="columns is-centered"> <b>{message}</b></div>}
        </div>
    );
}
export default LoadingView;