import React from 'react';
import axios from 'axios';
import { Route, Switch, Redirect } from 'react-router-dom';

import Nav from './components/Nav'
import SideMenu from './components/SideMenu'

import LoginPage from './components/LoginPage'
import HomePage from './components/HomePage'
import ClientsPage from './components/ClientsPage'
import MySolarlyboxesPage from './components/MySolarlyboxesPage'

import './App.scss'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.loginUser = this.loginUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.getIdFromToken = this.getIdFromToken.bind(this);
    this.getRole = this.getRole.bind(this);
    this.state = {
      userId: -1,
      isAuthenticated: false,
      isAdmin: false
    }
  }
  componentWillMount() {
    const token = window.localStorage.getItem('authToken');
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      // get userID
      const userId = this.getIdFromToken(token);
      // get Role
      this.getRole(userId)

      this.setState({ 
        isAuthenticated: true,
        userId: userId
      })
    }

  }
  logoutUser() {
    window.localStorage.clear();
    this.setState({
      isAuthenticated: false,
      userId: -1 });
  }
  loginUser(data) {
    // save token in localStorage
    window.localStorage.setItem('authToken', data.auth_token);
    // set token in default axios header
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.auth_token;
    // get userID
    const userId = this.getIdFromToken(data.auth_token);
    // get user role
    this.getRole(userId)
    
    this.setState({ 
      isAuthenticated: true,
      userId: userId
    })
  }
  getIdFromToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    var payload = JSON.parse(window.atob(base64))
    return payload['sub'];
  }
  getRole(userId) {
    // get user role
    const url = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/users/${userId}`;
    axios.get(url)
        .then(res => {
          this.setState({ 
            isAdmin: res.data.data.admin })
              })
        .catch(err => console.log(err))
  }
  render() {
    return (
          < >
            { !this.state.isAuthenticated &&
              <Switch>
                <Route exact path="/" render={ () => 
                  <LoginPage onLogin={this.loginUser}/>
                }/>
                <Route render={() => <Redirect to="/" /> }/>
              </Switch>
            }
          { this.state.isAuthenticated &&
            <>            
              <Nav data-cy="nav" onLogout={this.logoutUser}/>
              <div className="columns is-fullheight">
                <SideMenu admin={this.state.isAdmin} active={window.location.pathname} data-cy="sidemenu"/>
                <Switch>
                  <Route exact path="/" render={ () =>
                    <HomePage userId={this.state.userId}/>} /> 
                  <Route path="/mysolarlyboxes" render={ () => 
                    <MySolarlyboxesPage />} />
                  <Route path="/admin/clients" render={() => 
                    <ClientsPage />} />
                  }}  
                </Switch>
              </div>
            </>
          }
          </ >
        );
  }
}

export default App;
